<template>
	<div class="page-wrap">
		<div class="left-wrap">
			<div class="view-bg">
				<h3 class="main-title">赋能企业数字化转型</h3>
				<div class="main-bg" />
				<p class="footer-text">Copyright 长沙聚友才科技有限公司 备案号：<a class="footer-link" target="_blank" href="https://beian.miit.gov.cn/">湘ICP备2024049158号-1</a></p>
			</div>
			<div class="left-bottom-bg" />
		</div>
		<div class="right-wrap">
			<div class="content">
				<login class="main-area" />
			</div>
		</div>
	</div>
</template>

<script>
import Login from './login.vue'
export default {
  components: {
    Login
  }
}
</script>
  <style lang="less" scoped>
  .page-wrap{
    background: #F6F8FB;
    height: inherit;
    position: relative;
    min-height: 720px;
    min-width: 1440px;
    .left-wrap{
      position: relative;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding-right: 27%;
      .view-bg{
        position: absolute;
        height: 540px;
        width: 600px;
        left: 50%;
        margin-left: -580px;
        top: 50%;
        margin-top: -270px;
        z-index: 5;
        .main-title{
          height: 56px;
          font-size: 40px;
          color: #409EFF;
          // text-align: center;
          text-indent: 80px;
          font-style: normal;
          font-weight: normal;
          margin: 0 auto 0px;
          font-weight: 600;
        }
        .main-bg{
          width: 450px;
          height: 450px;
          // margin: 0 auto;
          margin-left: 80px;
          background-color: transparent;
          background-repeat: no-repeat;
          background-image: url('/imgs/main-bg.png');
          background-size: 100%;
          background-position: center;
        }
        .footer-text{
          height: 20px;
          padding-top: 10px;
          font-size: 14px;
          color: #999999;
          text-align: center;
          white-space: nowrap;
        }
        .footer-link {
          color: inherit;
          text-decoration: none
        }
      }
      .left-bottom-bg{
        position: absolute;
        bottom: 0;
        left: 0;
        height: 438px;
        right: 600px;
        z-index: 0;
        background-color: transparent;
        background-repeat: no-repeat;
        background-image: url('/imgs/left_bg.png');
        background-size: contain;
        background-position: bottom left;
      }
    }
    .right-wrap{
      position: absolute;
      width: 27%;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: #409EFF;
      background-repeat: no-repeat;
      background-image: url('/imgs/right_bg.png');
      background-size: contain;
      background-position: top left;
      z-index: 10;
      .content{
        height: 100%;
        width: 100%;
        position: relative;
          .main-area{
            position: absolute;
            top: 50%;
            margin-top: -245px;
            left: 0;
            margin-left: -220px;
            border-radius: 10px;
            z-index: 10;
          }
      }
    }
  }
  </style>
