<template>
	<div class="mod-login-box">
		<!-- <div class="login-type">
    <div class="login-type-icon" @click="onSwitchType"></div>
    <div class="login-type-tips">微信扫码登录</div>
  </div> -->
		<div class="tabs">
			<div class="tabs-active-bar" />
			<div :class="['tabs-item', tabIndex === 0 ? 'active' :'']" @click="tabChange(0)">账号登录</div>
			<!-- <div @click="tabChange(1)" :class="['tabs-item', tabIndex === 1 ? 'active' :'']">短信登录</div> -->
		</div>
		<div class="tabs-content">
			<div v-show="tabIndex === 0" class="content-item">
				<el-form ref="form" :model="form" :rules="rules" label-width="0px">
					<el-form-item prop="account">
						<el-input
							ref="formAccount"
							v-model="form.account"
							maxlength="50"
							clearable
							size="large"
							class="login-input"
							placeholder="请输入手机号码"
							@input="onClearAccountTrim"
							@keyup.enter.native="onPassSubmit"
							/>
					</el-form-item>
					<el-form-item prop="userPasswd">
						<el-input
							ref="formUserPasswd"
							v-model="form.userPasswd"
							:type="isPassword ? 'password' : 'text'"
							placeholder="请输入登录密码"
							maxlength="50"
							class="login-input"
							@keyup.enter.native="onPassSubmit"
							@input.native="inputPasswd"
							@paste.native.capture.prevent="() => { return false }"
							@contextmenu.native.capture.prevent="() => { return false }"
							@copy.native.capture.prevent="() => { return false }"
							@cut.native.capture.prevent="() => { return false }"
							>
							<i
								slot="suffix"
								:class="['crm-icon', isPassword ? 'icon-yanjing-bi' : 'icon-yanjing', 'login-eyes']"
								@click="isPassword=!isPassword"
								/>
						</el-input>
					</el-form-item>
					<el-form-item prop="code">
						<el-input
							ref="formCode"
							v-model="form.code"
							:maxlength="4"
							class="login-imgcode login-input"
							clearable
							placeholder="请输入验证码"
							@input="onClearCodeTrim"
							@keyup.enter.native="onPassSubmit"
							/>
						<img v-show="imageCodeUrl" class="img-code" title="点击刷新" :src="imageCodeUrl" width="90" height="40" @click="onfreshCode">
					</el-form-item>
					<el-form-item class="login-submit">
						<el-button
							type="primary"
							:loading="loading"
							class="login-btn"
							@click="onSubmit"
							>{{ loading?'登录中':'登录' }}</el-button>
					</el-form-item>
					<el-form-item label="" prop="remember">
						<!--el-checkbox label="下次自动登录" name="type" v-model="form.remember"></el-checkbox-->
						<!-- <el-button @click="onFindPass" class="login-get-password" type="text">忘记密码？</el-button> -->
						<!-- <el-button @click="onRegister" type="text" class="register-account">注册账号</el-button> -->
					</el-form-item>
				</el-form>
			</div>
			<div v-show="tabIndex" class="content-item">
				<el-form ref="phoneForm" :model="phoneForm" :rules="phoneRules" label-width="0px">
					<el-form-item prop="account">
						<el-input
							ref="phoneFormAccount"
							v-model.trim="phoneForm.account"
							:maxlength="11"
							clearable
							placeholder="请输入手机号码"
							@input="onClearAccountInput"
							@keyup.enter.native="phoneSubmit"
							/>
					</el-form-item>
					<el-form-item prop="code">
						<el-input
							ref="phoneFormCode"
							v-model.trim="phoneForm.code"
							placeholder="请输入短信验证码"
							maxlength="6"
							class="input-code"
							@input="onClearCodeInput"
							@keyup.enter.native="phoneSubmit"
							>
							<code-button slot="suffix" @change="onGetCode" />
						</el-input>
					</el-form-item>
					<el-form-item class="login-submit">
						<el-button
							type="primary"
							:loading="phoneloading"
							class="login-btn"
							@click="onSubmitPhoneForm"
							>{{ phoneloading?'登录中':'登录' }}</el-button>
					</el-form-item>
					<el-form-item label="" prop="remember">
						<!--el-checkbox label="下次自动登录" name="type" v-model="form.remember"></el-checkbox-->
						<!-- <el-button @click="onRegister" type="text" class="register-account">注册账号</el-button> -->
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
import codeButton from './code-button.vue'
import cookie from '@jw/ability-business-components/tools/lib/cookie'
import Server from './api.js'
import axios from 'axios'

axios.get('/version.json').then(res => {
  console.log('bbbbbbbbbbb', res.data)
})
const Api = new Server()

export default {
  components: {
    codeButton
  },
  data () {
    const self = this
    const checkPhoneACCount = (rule, value, callback) => {
      if (self.shouldCheckApi && self.errMessage === '账号不存在！') {
        callback(new Error('账号不存在！'))
      } else {
        if (!value) {
          callback(new Error('请输入手机号码'))
        } else {
          callback()
        }
      }
    }
    const checkPassword = (rule, value, callback) => {
      if (self.shouldCheckApi && self.errMessage === '密码错误！') {
        callback(new Error('密码错误'))
      } else {
        if (!value) {
          callback(new Error('密码不能为空'))
        } else {
          callback()
        }
      }
    }
    const checkcode = (rule, value, callback) => {
      if (!self.imageCodeOk) {
        callback(new Error(self.imageCodeMsg))
      } else {
        if (!value) {
          callback(new Error('请输入验证码'))
        } else {
          callback()
        }
      }
    }
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号码'))
      } else if (!/^1[0-9]{10}$/.test(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        Api.CheckPhoneValidate(value).then(({ data }) => {
          if (data.result) {
            callback()
          } else {
            callback(new Error('该手机号未注册'))
          }
        }).catch(res => {
          Server.$message(self.$message, res && res.response)
        })
      }
    }
    const checkPhoneCode = (rule, value, callback) => {
      if (self.shouldCheckCodeTotal) {
        if (self.checkFormOfSubmit && !value) {
          callback(new Error('请输入验证码'))
        } else {
          callback(new Error('操作过于频繁，请休息5分钟再试'))
        }
      } else {
        if (!value) {
          callback(new Error('请输入验证码'))
        } else if (value.length < 6) {
          callback(new Error('请输入6位数验证码'))
        } else if (!self.phoneCodeOk) {
          callback(new Error(self.phoneCodeMsg))
        } else {
          callback()
        }
      }
    }
    return {
      tabIndex: 0,
      form: {
        account: '',
        userPasswd: '',
        code: '',
        remember: true
      },
      rules: {
        account: [{ validator: checkPhoneACCount, trigger: 'blur' }],
        userPasswd: [{ validator: checkPassword, trigger: 'blur' }],
        code: [{ validator: checkcode, trigger: 'blur' }]
      },
      errMessage: '',
      guuid: '',
      isPassword: true,
      // shouldShowCode: false,
      shouldCheckApi: false,
      imageCodeOk: true,
      imageCodeMsg: '',
      imageCodeUrl: '',
      loading: false,
      // 验证码登录
      phoneForm: {
        account: '',
        code: ''
      },
      phoneCodeOk: true,
      phoneCodeMsg: '',
      shouldCheckCodeTotal: false,
      checkFormOfSubmit: false,
      phoneRules: {
        account: [{ required: true, validator: checkPhone, trigger: 'blur' }],
        code: [{ required: true, validator: checkPhoneCode, trigger: 'blur' }]
      },
      phoneloading: false
    }
  },
  mounted () {
    this.onfreshCode()
    this.shouldCheckTotal = (Number(cookie.getCookieUtil('_getphonecode')) || 0) > 5
    // this.shouldShowCode = (Number(cookie.getCookieUtil('_shouldshowcode')) || 0) > 5
    // window.addEventListener('beforeunload', function () {
    //   cookie.setCookieUtil('_shouldshowcode', 0, new Date(0))
    // })
  },
  methods: {
    onSwitchType () {
      this.$emit('change', 'qrcodeLogin')
    },
    onFindPass () {
      this.$emit('change', 'getPassword')
    },
    onRegister () {
      this.$emit('change', 'selectRegister')
    },
    tabChange (index) {
      this.tabIndex = index
    },
    inputPasswd (e) {
      const reg = /[\s\u2E80-\u2EFF\u2F00-\u2FDF\u3000-\u303F\u31C0-\u31EF\u3200-\u32FF\u3300-\u33FF\u3400-\u4DBF\u4DC0-\u4DFF\u4E00-\u9FBF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF]+/g
      e.target.value = e.target.value.replace(reg, '')
    },
    onClearAccountTrim (value) {
      this.form.account = value
      this.$nextTick(() => {
        this.form.account = value.replace(/\s/g, '')
      })
    },
    onClearCodeTrim (val) {
      this.form.code = val
      this.$nextTick(() => {
        this.form.code = val.replace(/[^A-Za-z0-9]/g, '')
      })
    },
    onfreshCode () {
      Api.getImageCode().then(res => {
        this.imageCodeUrl && (window.URL || window.webkitURL || window.mozURL).revokeObjectURL(this.imageCodeUrl)
        this.guuid = res.headers.uuid
        this.imageCodeUrl = (window.URL || window.webkitURL || window.mozURL).createObjectURL(res.data)
      }).catch((response) => {
        Server.$message(this.$message, response)
      })
    },
    onPassSubmit (e) {
      // if (this.shouldShowCode) {
      //   if (this.form.account && this.form.userPasswd && this.form.code) {
      //     e.target.blur()
      //     this.onSubmit()
      //   } else if (this.form.account && this.form.userPasswd && !this.form.code) {
      //     this.$refs.formCode.focus()
      //   } else if (this.form.account && !this.form.userPasswd && this.form.code) {
      //     this.$refs.formUserPasswd.focus()
      //   } else if (this.form.account && !this.form.userPasswd && !this.form.code) {
      //     this.$refs.formUserPasswd.focus()
      //   } else if (!this.form.account && this.form.userPasswd && this.form.code) {
      //     this.$refs.formAccount.focus()
      //   } else if (!this.form.account && this.form.userPasswd && !this.form.code) {
      //     this.$refs.formAccount.focus()
      //   } else if (!this.form.account && !this.form.userPasswd && this.form.code) {
      //     this.$refs.formAccount.focus()
      //   }
      // } else {
      if (this.form.account && !this.form.userPasswd) {
        this.$refs.formUserPasswd.focus()
      } else if (!this.form.account && this.form.userPasswd) {
        this.$refs.formAccount.focus()
      } else if (this.form.account && this.form.userPasswd) {
        e.target.blur()
        this.onSubmit()
      } else {}
      // }
    },
    onPassWordLogin () {
      this.loading = true
      // this.loginTotal()
      Api.login({
        loginType: 1,
        account: this.form.account,
        userPasswd: this.form.userPasswd,
        remember: this.form.remember,
        uuid: this.guuid,
        verifiCode: this.form.code
      }).then(res => {
        this.errMessage = ''
        this.$refs.form.clearValidate()
        debugger
        this._auth.setToken(res.data.access_token, true)

        if (this.$route.query.backUrl) {
          location.replace(decodeURIComponent(this.$route.query.backUrl))
          location.reload()
        } else {
          setTimeout(() => {
            location.href = location.origin
          }, 100)
        }
        this.loading = false
      }).catch((res) => {
        const { response } = res
        this.loading = false
        if (response && response.status === 400 && response.data && (response.data.msg === '账号不存在！' || response.data.msg === '密码错误！')) {
          this.errMessage = response.data.msg
          this.shouldCheckApi = true
          this.$refs.form.clearValidate()
          this.$refs.form.validate().finally(() => {
            this.shouldCheckApi = false
          })
        } else if (response && response.status === 400 && response.data && (response.data.msg === '验证码错误！' || response.data.msg === '验证码已失效,请重新获取' || response.data.msg === '验证码已失效,请重新获取')) {
          this.imageCodeOk = false
          this.imageCodeMsg = response.data.msg
          this.$refs.form.validateField('code', () => {})
        } else {
          Server.$message(this.$message, response)
        }
        this.onfreshCode()
        // if ((Number(cookie.getCookieUtil('_shouldshowcode')) || 0) > 4) {
        //   this.onfreshCode()
        //   this.shouldShowCode = true
        // }
      })
    },
    onSubmit () {
      this.$refs.form.clearValidate()
      this.imageCodeOk = true
      this.imageCodeMsg = ''
      this.$refs.form.validate().then(res => {
        this.onPassWordLogin()
      }).catch(() => {})
      // this.$refs.form.validate().then(res => {
      //   Api.checkImageCode(this.form.code, this.guuid).then(({ data }) => {
      //     if (data.result) {
      //       this.onPassWordLogin()
      //     } else {
      //       this.imageCodeOk = false
      //       this.imageCodeMsg = '验证码错误'
      //       this.$refs.form.validateField('code', () => {})
      //       this.onfreshCode()
      //     }
      //   }).catch(({ response }) => {
      //     this.onfreshCode()
      //     if (response && response.status === 400 && response.data && (response.data.msg === '验证码已失效，请重新获取' || response.data.msg === '验证码已失效,请重新获取')) {
      //       this.imageCodeOk = false
      //       this.imageCodeMsg = '验证码已失效，请重新获取'
      //       this.$refs.form.validateField('code', () => {})
      //     } else {
      //       Api.message(this.$message, response)
      //     }
      //   })
      // }).catch(() => {})
    },
    // loginTotal () {
    //   let total = Number(cookie.getCookieUtil('_shouldshowcode')) || 0
    //   if (total > 4) {
    //     cookie.setCookieUtil('_shouldshowcode', 5)
    //   }
    //   if (total < 5) {
    //     cookie.setCookieUtil('_shouldshowcode', total + 1, new Date(Date.now() + 2 * 60 * 1000))
    //   }
    // },
    onClearAccountInput (value) {
      this.phoneForm.account = value
      this.$nextTick(() => {
        this.phoneForm.account = value.replace(/\D/g, '')
      })
    },
    onClearCodeInput (value) {
      this.phoneForm.code = value
      this.$nextTick(() => {
        this.phoneForm.code = value.replace(/\D/g, '')
      })
    },
    onGetCode (done) {
      this.$refs.phoneForm.validateField('account', (e) => {
        if (!e) {
          const total = Number(cookie.getCookieUtil('_getphonecode')) || 0
          if (total === 5) {
            this.shouldCheckCodeTotal = true
            cookie.setCookieUtil('_getphonecode', 6, new Date(Date.now() + 5 * 60 * 1000))
            setTimeout(() => {
              this.shouldCheckCodeTotal = false
            }, 5 * 60 * 1000)
            this.$refs.phoneForm.validateField('code', () => {})
          }
          if (total > 5) {
            this.$refs.phoneForm.validateField('code', () => {})
          }
          if (total < 5) {
            this.$refs.phoneForm.clearValidate('code')
            cookie.setCookieUtil('_getphonecode', total + 1, new Date(Date.now() + 2 * 60 * 1000))
            Api.postPhoneCode({
              phone: this.phoneForm.account,
              type: 1
            }).catch(({ response }) => {
              Server.$message(this.$message, response)
            })
            this.$refs.phoneFormCode.focus()
            done && done()
          }
        }
      })
    },
    phoneSubmit (e) {
      if (this.phoneForm.account && !this.phoneForm.code) {
        this.$refs.phoneFormCode.focus()
      } else if (!this.phoneForm.account && this.phoneForm.code) {
        this.$refs.phoneFormAccount.focus()
      } else if (this.phoneForm.account && this.phoneForm.code) {
        e.target.blur()
        this.onSubmitPhoneForm()
      } else {}
    },
    onSubmitPhoneForm () {
      this.checkFormOfSubmit = true
      this.phoneCodeMsg = ''
      this.phoneCodeOk = true
      this.$refs.phoneForm.validate().then(res => {
        this.phoneloading = true
        this.$axios.post('/api/login', {
          loginType: 2,
          account: this.phoneForm.account,
          verifiCode: this.phoneForm.code,
          remember: false
        }).then(res => {
          this._auth.setToken(res.data.access_token, true)

          cookie.setCookieUtil('_getphonecode', 0, new Date(0))
          this.phoneloading = false
          this.phoneCodeMsg = ''
          this.phoneCodeOk = true
          location.replace(this.$route.query.backUrl || location.origin)
        }).catch(({ response }) => {
          this.phoneloading = false
          if (response && response.status === 400 && response.data && (response.data.msg === '验证码已失效，请重新获取' || response.data.msg === '验证码已失效,请重新获取' || response.data.msg === '验证码错误')) {
            this.phoneCodeMsg = response.data.msg
            this.phoneCodeOk = false
            this.shouldCheckApi = true
            this.$refs.phoneForm.clearValidate()
            this.$refs.phoneForm.validate().finally(() => {
              this.shouldCheckApi = false
            })
          } else {
            Server.$message(this.$message, response)
          }
        })
      }).catch(() => {}).finally(() => {
        this.checkFormOfSubmit = false
      })
    }
  }
}
</script>

<style lang="less">
.mod-login-box{
  height: 100%;
  .login-type{
    position: relative;
    height: 36px;
    width: 100%;
    .login-type-icon{
      position: absolute;
      right: -20px;
      top: -5px;
      width: 48px;
      height: 48px;
      background-repeat: no-repeat;
      background-image: url('/imgs/qrcode-icon.png');
      background-size: 100%;
      background-position: center;
      cursor: pointer;
      z-index: 10;
    }
    .login-type-tips{
      position: absolute;
      right: 30px;
      top: -5px;
      width: 96px;
      height: 24px;
      line-height: 22px;
      font-size: 12px;
      color: #61667C;
      box-sizing: border-box;
      border: 1px solid #e5e5e5;
      background: #fff;
      z-index: 11;
      text-indent: 10px;
      border-radius: 4px;
      &::before{
        position: absolute;
        content: '';
        top: 7px;
        right: -9px;
        width: 0;
        height: 0;
        overflow: hidden;
        font-size: 0;
        line-height: 0;
        border: 4px;
        border-style: dashed dashed solid dashed;
        border-color: transparent transparent transparent #e5e5e5;
      }
      &::after{
        position: absolute;
        content: '';
        top: 7px;
        right: -8px;
        width: 0;
        height: 0;
        overflow: hidden;
        font-size: 0;
        line-height: 0;
        border: 4px;
        border-style: dashed dashed solid dashed;
        border-color: transparent transparent transparent #fff;
      }
    }
  }
  .tabs{
    width: 100%;
    height: 50px;
    margin: 0px auto 0;
    position: relative;
    &::after{
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: #e4e7ed;
    }
    .tabs-active-bar{
      position: absolute;
      bottom: 0;
      // left: 20px;
      left: 50%;
      height: 2px;
      width: 120px;
      background-color: #409eff;
      z-index: 1;
      transition: transform .3s cubic-bezier(.645,.045,.355,1);
      list-style: none;
      transform: translateX(-50%);
    }
    .tabs-item{
      width: 160px;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      color: #666;
      transition: .3s;
      box-shadow: none;
      text-align: center;
      margin: 0 auto;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -o-box-sizing: border-box;
      -ms-box-sizing: border-box;
      box-sizing: border-box;
      cursor: pointer;
      font-weight: 600;
      &.active{
        color: #409eff;
      }
    }
  }
  .tabs-content{
    width: 320px;
    .content-item{
      width: 100%;
      height: 100%;
      padding-top: 30px;
      .login-btn {
        line-height: 20px;
        font-size: 14px;
        width: 100%;
      }
      .login-submit {
        margin-bottom: 0px;
      }
      .login-eyes {
        font-size: 18px;
        cursor: pointer;
      }
      .login-imgcode{
        width: 215px;
        float: left;
        zoom: 1;
        margin-right: 10px;
      }
      .img-code{
        float: right;
        cursor: pointer;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        border-radius: 4px;
      }
      .login-get-password{
        // float: right;
        // color: #333;
        text-decoration:underline;
        font-size: 14px;
        color: #787878;
        padding-top: 20px;
        &:active{
          color: #409EFF;
          text-decoration:underline;
        }
      }
      .register-account{
        padding-top: 20px;
        float: right;
      }
      .input-code .el-input__suffix{
        right: 15px;
      }
      .el-form-item.is-success .el-input__inner{
        border-color: #dcdfe6;
      }
    }
  }
  .login-input {
    line-height: 40px;
    margin-bottom: 8px;
    .el-input__inner {
      height: 40px;
    }
  }
}
</style>
