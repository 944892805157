<template>
	<div class="mod-qrcode-login">
		<div class="login-type">
			<div class="login-type-icon" @click="onSwitchType" />
			<div class="login-type-tips">账号密码登录</div>
		</div>
		<h3 class="login-title">微信扫码登录</h3>
		<div class="qrcode-area">
			<div v-show="qrcodeUrl" class="qrcode-bg" :style="`background-image:url(${qrcodeUrl})`" />
			<div v-if="scanfSuccess" class="qrcode-scanf-success">
				<img class="img-icon" src="/imgs/scanf-success-icon.svg">
				<h5 class="main-title">扫码成功</h5>
				<p class="sub-title">等待绑定操作</p>
			</div>
			<div v-if="timeout" class="qrcode-timeout">
				<img class="img-icon" style="cursor: pointer;" src="/imgs/timeout-icon.svg" @click="createCode">
				<h5 class="main-title">二维码失效</h5>
				<p class="sub-title">请刷新重新扫描</p>
			</div>
		</div>
		<p class="login-tips">请使用微信扫描二维码登录</p>
	</div>
</template>

<script>
import qrcode from 'qrcode'
import Server from './api.js'
const Api = new Server()
export default {
  data () {
    return {
      qrcodeUrl: '',
      scanfSuccess: false,
      timeout: false,
      timeoutFlag: null,
      loginFlag: null,
      shouldLogin: true,
      qrcode: null
    }
  },
  mounted () {
    this.createCode()
  },
  beforeDestroy () {
    this.timeoutFlag && clearTimeout(this.timeoutFlag)
    this.shouldLogin = false
    this.loginFlag && clearTimeout(this.loginFlag)
  },
  methods: {
    onSwitchType () {
      this.$emit('change', 'loginBox')
    },
    createCode () {
      Promise.all([Api.getQrcodeId(), Api.getDomain()]).then(res => {
        this.qrcode = res[0].data.qrcode
        qrcode.toDataURL(`${res[1].data.value}/m/sign_in.html?qrcode=${this.qrcode}`, { errorCorrectionLevel: 'M', margin: '0' }).then(url => {
          this.qrcodeUrl = url
          this.scanfSuccess = false
          this.timeout = false
          this.shouldLogin = true
          this.timeoutFlag && clearTimeout(this.timeoutFlag)
          this.timeoutFlag = null
          this.codeTimeout()
          this.login()
        })
      }).catch(({ response }) => {
        Server.$message(this.$message, response)
      })
    },
    login () {
      this.loginFlag = setTimeout(() => {
        this.shouldLogin && Api.login({ loginType: 5, uuid: this.qrcode }).then(({ data }) => {
          clearTimeout(this.loginFlag)
          this._auth.setToken(data.access_token, true)
          location.replace(this.$route.query.backUrl || location.origin)
        }).catch(({ response }) => {
          if (response && response.status === 406) {
            if (response.data.code === 406011) {
              this.scanfSuccess = true
              this.timeout = false
            }
          } else {
            Server.$message(this.$message, response)
          }
          this.login()
        })
      }, 4 * 1000)
    },
    codeTimeout () {
      this.timeoutFlag = setTimeout(() => {
        this.timeout = true
        this.scanfSuccess = false
        this.qrcode = null
        this.shouldLogin = false
        this.loginFlag && clearTimeout(this.loginFlag)
        this.loginFlag = null
      }, 5 * 60 * 1000)
    }
  }
}
</script>

<style lang="less">
.mod-qrcode-login{
  height: 100%;
  .login-type{
    position: relative;
    height: 48px;
    width: 100%;
    .login-type-icon{
      position: absolute;
      right: -20px;
      top: -5px;
      width: 48px;
      height: 48px;
      background-repeat: no-repeat;
      background-image: url('/imgs/pc-icon.png');
      background-size: 100%;
      background-position: center;
      cursor: pointer;
      z-index: 10;
    }
    .login-type-tips{
      position: absolute;
      right: 30px;
      top: -5px;
      width: 96px;
      height: 24px;
      line-height: 22px;
      font-size: 12px;
      color: #61667C;
      box-sizing: border-box;
      border: 1px solid #e5e5e5;
      background: #fff;
      z-index: 11;
      text-indent: 10px;
      border-radius: 4px;
      &::before{
        position: absolute;
        content: '';
        top: 7px;
        right: -9px;
        width: 0;
        height: 0;
        overflow: hidden;
        font-size: 0;
        line-height: 0;
        border: 4px;
        border-style: dashed dashed solid dashed;
        border-color: transparent transparent transparent #e5e5e5;
      }
      &::after{
        position: absolute;
        content: '';
        top: 7px;
        right: -8px;
        width: 0;
        height: 0;
        overflow: hidden;
        font-size: 0;
        line-height: 0;
        border: 4px;
        border-style: dashed dashed solid dashed;
        border-color: transparent transparent transparent #fff;
      }
    }
  }
  .login-title{
    height: 30px;
    line-height: 30px;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    margin: 27px 0 20px;
    color: #333;
  }
  .qrcode-area{
    width: 208px;
    height: 208px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    margin: 0 auto 20px;
    position: relative;
    .qrcode-bg{
      width: 160px;
      height: 160px;
      margin: 24px auto 0;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
    }
    .qrcode-scanf-success,.qrcode-timeout{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0,0,0,0.8);
      text-align: center;
      .img-icon{
        width: 40px;
        height: 40px;
        padding-top: 56px;
        padding-bottom: 12px;
        box-sizing: content-box;
      }
      .main-title{
        color: #fff;
        height: 22px;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        margin: 0;
        font-style: normal;
        font-weight: normal;
      }
      .sub-title{
        color: #fff;
        height: 22px;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        margin: 0;
        font-style: normal;
        font-weight: normal;
      }
    }
  }
  .login-tips{
    height: 22px;
    line-height: 22px;
    font-size: 16px;
    color: #333;
    text-align: center;
  }
}
</style>
