<template>
	<div class="mod-window-select-register">
		<h3 class="main-title">选择注册类型</h3>
		<p class="sub-title"><span>已有账号？</span><el-button style="padding-top:6px;" type="text" @click="onSelectLogin">直接登录</el-button></p>
		<div class="select-group">
			<div :class="['group-item', radio === 1 ? 'active':'']" @click="onSelectRadio(1)">
				<div class="radio-box">
					<div class="my-radio" />
				</div>
				<div class="item-content">
					<h5 class="main-text">注册企业账号</h5>
					<p class="sub-text">注册后，企业主账号登陆系统后台，可添加分配子账号</p>
				</div>
			</div>
			<div :class="['group-item', radio === 2 ? 'active':'']" @click="onSelectRadio(2)">
				<div class="radio-box">
					<div class="my-radio" />
				</div>
				<div class="item-content">
					<h5 class="main-text">加入已有企业成为一员</h5>
					<p class="sub-text">若您的企业已拥有主账号，您可申请加入成为企业一员，拥有账号登陆</p>
				</div>
			</div>
		</div>
		<el-button class="select-button" :disabled="!radio" type="primary" @click="onStep">下一步</el-button>
	</div>
</template>

<script>
export default {
  data () {
    return {
      radio: null
    }
  },
  methods: {
    onSelectRadio (index) {
      this.radio = index
    },
    onSelectLogin () {
      this.$emit('change', 'loginBox')
    },
    onStep () {
      this.$emit('change', this.radio === 1 ? 'companyRegister' : 'employeeRegister')
    }
  }
}
</script>

<style lang="less">
.mod-window-select-register{
  box-sizing: border-box;
  padding-top: 25px;
  .main-title{
    height: 25px;
    line-height: 25px;
    margin: 0;
    font-size: 18px;
    color: #333;
    font-weight: 600;
  }
  .sub-title{
    height: 42px;
    line-height: 20px;
    font-size: 14px;
    color: #666;
  }
  .select-group{
    box-sizing: border-box;
    width: 100%;
    .group-item{
      height: 130px;
      background: #FFFFFF;
      border: 1px solid #D6D6D6;
      border-radius: 6px;
      position: relative;
      cursor: pointer;
      &.active{
        border: 1px solid #409EFF;
          .radio-box .my-radio{
            border-color: #409eff;
            background: #409eff;
            &:after{
              width: 4px;
              height: 4px;
              border-radius: 100%;
              background-color: #fff;
              content: "";
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%,-50%) scale(1);
              transition: transform .15s ease-in;
            }
          }
      }
      .radio-box{
        position: absolute;
        left: 0;
        top: 0;
        height: 130px;
        width: 52px;
        vertical-align: middle;
        text-align: center;
        line-height: 130px;
        .my-radio{
          border: 1px solid #dcdfe6;
          border-radius: 100%;
          width: 14px;
          height: 14px;
          background-color: #fff;
          position: relative;
          cursor: pointer;
          display: inline-block;
          box-sizing: border-box;
          &:hover{
            border-color: #409eff;
          }
        }
      }
      .item-content{
        box-sizing: border-box;
        width: 100%;
        padding-left: 54px;
        padding-right: 16px;
        .main-text{
          margin: 24px 0 8px;
          height: 22px;
          font-size: 16px;
          font-weight: 600;
          color: #333;
        }
        .sub-text{
          font-size: 14px;
          color: #787878;
          line-height: 24px;
        }
      }
      &:last-child{
        margin-top: 20px;
      }
    }
  }
  .select-button{
    width: 100%;
    margin-top: 20px;
  }
}
</style>
