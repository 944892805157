<template>
	<div class="login">
		<component :is="currentComponent" :company-name="companyName" :mobile="mobile" @change="ComponentChange" />
	</div>
</template>

<script lang="jsx">
import { analyzeAgent } from './isMobile'
import getPassword from './get-password.vue'
import loginBox from './login-box.vue'
import selectRegister from './select-register.vue'
import companyRegister from './company-register.vue'
import employeeRegister from './employee-register.vue'
import qrcodeLogin from './qrcode-login.vue'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    getPassword,
    // eslint-disable-next-line vue/no-unused-components
    loginBox,
    // eslint-disable-next-line vue/no-unused-components
    selectRegister,
    // eslint-disable-next-line vue/no-unused-components
    companyRegister,
    // eslint-disable-next-line vue/no-unused-components
    employeeRegister,
    // eslint-disable-next-line vue/no-unused-components
    qrcodeLogin
  },
  data () {
    return {
      currentComponent: 'loginBox',
      companyName: null,
      mobile: null
    }
  },
  created () {
    analyzeAgent(navigator.userAgent) && this.$router.replace({ path: '/m-auth', query: this.$route.query })
  },
  methods: {
    ComponentChange (ComponentName, companyName, mobile) {
      this.currentComponent = ComponentName
      this.companyName = companyName
      this.mobile = mobile
    }
  }
}
</script>

<style lang="less" scoped>
  .login {
    width: 380px;
    min-height: 490px;
    background-color: #fff;
    padding: 50px 30px 0px;
    border-radius: 3px;
    position: relative;
  }
</style>
