import axios from 'axios'
import Api from '@/apis/index'
const { apiCrmUs, apiOperation, apiCrmOS, apiMps } = Api.path

export default class Server extends Api {
  login (o) {
    return axios.post('/api/login', o)
  }

  // 图片验证码
  getImageCode () {
    // return `${apiCrmUs}image/verifi-code?t=${Date.now()}`
    return axios.get(`${apiCrmUs}image/verifi-code?t=${Date.now()}`, { responseType: 'blob' })
  }

  // 校验图形验证码
  checkImageCode (code, uuid) {
    return axios.get(`${apiCrmUs}proof/image/verifi-code?code=${code}&uuid=${uuid}`)
  }

  // 修改密码
  findPassword (o) {
    return axios.put(`${apiCrmUs}forget-pwd/user/password`, o)
  }

  // 校验手机号码是否已注册
  CheckPhoneValidate (phone) {
    return axios.get(`${apiCrmUs}validate/user/phone/${phone}`)
  }

  // 发送短信验证码
  postPhoneCode (o) {
    return axios.post(`${apiCrmUs}send/msg/verifi-code`, o)
  }

  // 校验短信验证码
  checkPhoneCode (o) {
    return axios.post(`${apiCrmUs}proof/msg/verifi-code`, o)
  }

  // 获取开通产品列表
  getTools () {
    return axios.get(`${apiOperation}regist/tools`)
  }

  // 注册企业
  postCompany (o) {
    return axios.post(`${apiOperation}regist/organ`, o)
  }

  // 注册员工
  postEmployee (o) {
    return axios.post(`${apiOperation}regist/employ`, this.clearNull(o))
  }

  // 补充员工信息
  addEmployeeInfo (o) {
    return axios.post(`${apiOperation}regist/employ/append`, this.clearNull(o))
  }

  // 根据parnterID获取机构名称
  getPartenerName (partnerId) {
    return axios.get(`${apiCrmOS}partner/${partnerId}/name`)
  }

  // 获取唯一码
  getQrcodeId () {
    return axios.get(`${apiCrmUs}user/login/qrcode`)
  }

  getDomain () {
    return axios.get(`${apiMps}env`, { params: { env_name: 'MPS_DOMAIN' }})
  }
  // // 通知二维码已扫描
  // putQrcodeStatus (o) {
  //   return axios.put(`${apiCrmUs}user/login/qrcode/lock`, o)
  // },
  // // 绑定账号
  // postBindAccount (o) {
  //   return axios.post(`${apiCrmUs}user/join-weixin`, o)
  // },
  // // 获取aapid
  // exchangeAppid () {
  //   return axios.get(`${apiCrmOS}appid`, { params: { typeId: 3, partnerId: 0 } })
  // },
  // // 扫码登录
  // putCode2Login (o) {
  //   return axios.put(`${apiCrmOS}login/qrcode/auth`, o)
  // }
}

