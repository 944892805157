<template>
	<div class="mod-code-button">
		<el-button v-if="isFirst && !isCountdown" type="text" @click="onGetCode(true)">获取验证码</el-button>
		<span v-if="isCountdown">{{ time }}s</span>
		<el-button v-if="!isFirst && !isCountdown" class="get-again" type="text" @click="onGetCode(false)">重新获取</el-button>
	</div>
</template>

<script>
export default {
  props: {},
  data () {
    return {
      isFirst: true,
      isCountdown: false,
      time: 60
    }
  },
  methods: {
    onGetCode (isFirst) {
      this.$emit('change', () => {
        isFirst && (this.isFirst = false)
        this.countdowm()
      })
    },
    countdowm () {
      this.isCountdown = true
      const timer = setInterval(() => {
        this.time--
        if (this.time === 0) {
          clearInterval(timer)
          this.isCountdown = false
          this.time = 60
        }
      }, 1000)
    }
  }
}
</script>

<style lang="less">
.mod-code-button{
  .get-again{
    color: #cc7a45;
  }
}
</style>
