<template>
	<div class="mod-employee-register">
		<template v-if="active=== 1">
			<h3 class="main-title">填写员工注册信息</h3>
			<p class="sub-title"><span>已有账号？</span><el-button style="padding-top:6px;" type="text" @click="onSelectLogin">直接登录</el-button></p>
			<el-form ref="form" :model="form" :rules="rules" label-width="0px">
				<el-form-item prop="companyName">
					<el-input
						v-model.trim="form.companyName"
						maxlength="50"
						clearable
						placeholder="请输入企业名称"
						/>
				</el-form-item>
				<el-form-item prop="employeeName">
					<el-input
						v-model.trim="form.employeeName"
						maxlength="10"
						clearable
						placeholder="请输入员工姓名"
						/>
				</el-form-item>
				<el-form-item prop="mobile">
					<el-input
						ref="phoneFormAccount"
						v-model.trim="form.mobile"
						:maxlength="11"
						clearable
						placeholder="请输入手机号"
						@input="onClearAccountInput"
						/>
				</el-form-item>
				<el-form-item prop="code">
					<el-input
						ref="phoneFormCode"
						v-model.trim="form.code"
						placeholder="请输入短信验证码"
						maxlength="6"
						class="input-code"
						@input="onClearCodeInput"
						>
						<code-button slot="suffix" @change="onGetCode" />
					</el-input>
				</el-form-item>
				<el-form-item class="company-register-submit" prop="button">
					<el-button
						type="primary"
						class="register-prev-btn"
						@click="onPrev"
						>上一步</el-button>
					<el-button
						type="primary"
						:loading="loading"
						class="register-submit-btn"
						@click="onSubmit"
						>{{ loading?'提交中':'提交' }}</el-button>
				</el-form-item>
			</el-form>
		</template>
		<template v-if="active === 2">
			<h3 class="main-title">填写员工信息</h3>
			<p class="sub-title"><span>请完善您的个人信息，便于管理员审核</span></p>
			<el-form ref="infoForm" :model="infoForm" :rules="infoRules" label-width="0px">
				<el-form-item prop="department">
					<el-input
						v-model.trim="infoForm.department"
						maxlength="20"
						clearable
						placeholder="请输入所在部门"
						/>
				</el-form-item>
				<el-form-item prop="sex">
					<el-select v-model="infoForm.sex" style="width:100%;" placeholder="请选择性别">
						<el-option
							v-for="item in [{label:'女',value:'0'},{label:'男',value:'1'}]"
							:key="item.value"
							:label="item.label"
							:value="item.value"
							/>
					</el-select>
				</el-form-item>
				<el-form-item prop="empNo">
					<el-input
						v-model.trim="infoForm.empNo"
						maxlength="10"
						clearable
						placeholder="请输入员工工号"
						/>
				</el-form-item>
				<el-form-item prop="email">
					<el-input
						v-model.trim="infoForm.email"
						maxlength="50"
						clearable
						placeholder="请输入邮箱地址"
						/>
				</el-form-item>
				<el-form-item prop="imgCode">
					<el-input
						v-model.trim="infoForm.imgCode"
						:maxlength="4"
						class="login-imgcode"
						clearable
						placeholder="请输入验证码"
						@input="onClearCodeTrim"
						/>
					<img class="img-code" title="点击刷新" :src="imageCodeUrl" width="90" height="40" @click="onfreshCode">
				</el-form-item>
				<el-form-item prop="button">
					<el-button style="width:100%" :loading="loading" type="primary" @click="onAddInfo">提交</el-button>
				</el-form-item>
			</el-form>
		</template>
		<template v-if="active === 3">
			<div class="main-tips">
				<div class="wait-img" />
				<h3 class="wait-title">等待审核中…</h3>
			</div>
			<p class="sub-tips">您的申请信息已提交，请耐心等待企业审核，审核通过后，将短信通知您</p>
			<el-button type="primary" class="reback-button" plain @click="onReback">返回主页</el-button>
		</template>
	</div>
</template>

<script>
import codeButton from './code-button.vue'
import cookie from '@jw/ability-business-components/tools/lib/cookie'
import Server from './api.js'
const Api = new Server()
export default {
  components: {
    codeButton
  },
  data () {
    const self = this
    const checkCompanyName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入公司名称'))
      } else if (self.companyNameErrorText) {
        callback(new Error(self.companyNameErrorText))
      } else {
        callback()
      }
    }
    const checkEmployeeName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入员工姓名'))
      } else if (self.employeeNameErrorText) {
        callback(new Error(self.employeeNameErrorText))
      } else {
        callback()
      }
    }
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号码'))
      } if (self.mobileErrorText) {
        callback(new Error(self.mobileErrorText))
      } else if (!/^1[0-9]{10}$/.test(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    }
    const checkPhoneCode = (rule, value, callback) => {
      if (self.shouldCheckCodeTotal) {
        callback(new Error('操作过于频繁，请休息5分钟再试'))
      } else {
        if (!value) {
          callback(new Error('请输入验证码'))
        } else if (value.length < 6) {
          callback(new Error('请输入6位数验证码'))
        } else if (self.codeErrorText) {
          callback(new Error(self.codeErrorText))
        } else {
          callback()
        }
      }
    }
    return {
      active: 1,
      loading: false,
      empRegId: null,
      form: {
        companyName: '',
        mobile: '',
        code: '',
        employeeName: ''
      },
      companyNameErrorText: '',
      mobileErrorText: '',
      codeErrorText: '',
      employeeNameErrorText: '',
      shouldCheckCodeTotal: false,
      imageCodeUrl: null,
      guuid: null,
      rules: {
        companyName: [{ validator: checkCompanyName, trigger: 'blur' }],
        mobile: [{ validator: checkPhone, trigger: 'blur' }],
        code: [{ validator: checkPhoneCode, trigger: 'blur' }],
        employeeName: [{ validator: checkEmployeeName, trigger: 'blur' }],
        button: []
      },
      imageCodeMsg: null,
      infoForm: {
        department: null,
        empNo: null,
        sex: null,
        email: null,
        imgCode: null
      },
      infoRules: {
        department: [],
        empNo: [],
        sex: [],
        email: [{
          validator: (rule, value, callback) => {
            if (value && !/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(value)) {
              callback(new Error('格式不正确！'))
            } else {
              callback()
            }
          },
          required: false
        }],
        imgCode: [{
          validator: (rule, value, callback) => {
            if (self.imageCodeMsg) {
              callback(new Error(self.imageCodeMsg))
            } else {
              if (!value && (self.infoForm.department || self.infoForm.empNo || self.infoForm.sex || self.infoForm.email)) {
                callback(new Error('请输入验证码'))
              } else {
                callback()
              }
            }
          },
          required: false,
          trigger: 'blur'
        }],
        button: []
      }
    }
  },
  mounted () {
    if (this.$attrs.companyName && this.$attrs.mobile) {
      this.form.companyName = this.$attrs.companyName
      this.form.mobile = this.$attrs.mobile
    }
    this.shouldCheckTotal = (Number(cookie.getCookieUtil('_register_company')) || 0) > 5
  },
  methods: {
    onSelectLogin () {
      this.$emit('change', 'loginBox')
    },
    onClearAccountInput (value) {
      this.form.mobile = value
      this.$nextTick(() => {
        this.form.mobile = value.replace(/\D/g, '')
      })
    },
    onClearCodeInput (value) {
      this.codeErrorText = null
      this.form.code = value
      this.$nextTick(() => {
        this.form.code = value.replace(/\D/g, '')
      })
    },
    onGetCode (done) {
      this.$refs.form.validateField('mobile', (e) => {
        if (!e) {
          const total = Number(cookie.getCookieUtil('_register_employee')) || 0
          if (total === 5) {
            this.shouldCheckCodeTotal = true
            cookie.setCookieUtil('_register_employee', 6, new Date(Date.now() + 5 * 60 * 1000))
            setTimeout(() => {
              this.shouldCheckCodeTotal = false
            }, 5 * 60 * 1000)
            this.$refs.form.validateField('code', () => {})
          }
          if (total > 5) {
            this.$refs.form.validateField('code', () => {})
          }
          if (total < 5) {
            this.$refs.form.clearValidate('code')
            cookie.setCookieUtil('_register_employee', total + 1, new Date(Date.now() + 2 * 60 * 1000))
            Api.postPhoneCode({
              phone: this.form.mobile,
              type: 6,
              isCheckPhone: 2
            }).catch(({ response }) => {
              Server.$message(this.$message, response)
            })
            this.$refs.phoneFormCode.focus()
            done && done()
          }
        }
      })
    },
    onPrev () {
      this.$emit('change', 'selectRegister')
    },
    onSubmit () {
      this.$refs.form.validate().then(res => {
        this.loading = true
        Api.postEmployee({
          pName: this.form.companyName,
          phone: this.form.mobile,
          code: this.form.code,
          name: this.form.employeeName
        }).then(({ data }) => {
          if (Number(data.result) === 0) {
            this.active = 3
          } else {
            this.onfreshCode()
            this.active = 2
            this.empRegId = data.empRegId
          }
        }).catch(({ response }) => {
          if (response && response.status === 406) {
            const data = response.data
            if (data.code === 406001) {
              this.codeErrorText = data.msg
              this.$refs.form.validateField('code', () => {})
            }
            if (data.code === 406003) {
              this.$confirm('注册失败，当前企业还未注册企业主账号，请确认企业名称是否有误？', '提示', {
                confirmButtonText: '重新填写',
                showCancelButton: false,
                customClass: 'dialog-mod-register-employee'
              }).then(res => {
              }).catch(() => {})
            }
            if (data.code === 406004) {
              this.$confirm('注册失败，当前手机号已被其他账号使用，请直接登录已有账号。', '提示', {
                confirmButtonText: '立即登录',
                showCancelButton: false,
                customClass: 'dialog-mod-register-employee'
              }).then(res => {
                this.$emit('change', 'loginBox')
              }).catch(() => {})
            }
          } else {
            Server.$message(this.$message, response)
          }
        }).finally(() => {
          this.loading = false
        })
      }).catch(() => {})
    },
    onClearCodeTrim (val) {
      this.imageCodeMsg = null
      this.infoForm.imgCode = val
      this.$nextTick(() => {
        this.infoForm.imgCode = val.replace(/[^A-Za-z0-9]/g, '')
      })
    },
    onfreshCode () {
      Api.getImageCode().then(res => {
        this.imageCodeUrl && (window.URL || window.webkitURL || window.mozURL).revokeObjectURL(this.imageCodeUrl)
        this.guuid = res.headers.uuid
        this.imageCodeUrl = (window.URL || window.webkitURL || window.mozURL).createObjectURL(res.data)
      }).catch((response) => {
        Server.$message(this.$message, response)
      })
    },
    onAddInfo () {
      this.$refs.infoForm.validate().then(res => {
        if (this.infoForm.department || this.infoForm.empNo || this.infoForm.sex || this.infoForm.email) {
          this.loading = true
          Api.addEmployeeInfo({
            empRegId: this.empRegId,
            department: this.infoForm.department,
            sex: this.infoForm.sex,
            empNo: this.infoForm.empNo,
            email: this.infoForm.email,
            code: this.infoForm.imgCode,
            uuid: this.guuid
          }).then(res => {
            this.active = 3
          }).catch(({ response }) => {
            if (response && response.status === 406 && response.data && (response.data.msg === '验证码错误！' || response.data.msg === '验证码已失效,请重新获取' || response.data.msg === '验证码已失效,请重新获取')) {
              this.imageCodeMsg = response.data.msg
              this.$refs.infoForm.validateField('imgCode', () => {})
            } else {
              Server.$message(this.$message, response)
            }
            this.onfreshCode()
          }).finally(() => {
            this.loading = false
          })
        } else {
          this.active = 3
        }
      }).catch(() => {})
    },
    onReback () {
      const links = document.createElement('a')
      links.style.display = 'none'
      links.setAttribute('href', 'http://www.shijqq.com/');
      (document.body[0] || document.body).appendChild(links)
      setTimeout(() => {
        links.click();
        (document.body[0] || document.body).removeChild(links)
      }, 10)
    }
  }
}
</script>

<style lang="less">
.dialog-mod-register-employee{
  width: 420px;
  height: 200px;
  .el-message-box__header{
    border-bottom: 1px solid #cbcdd4;
  }
  .el-message-box__content{
    padding: 30px 15px;
  }
}
.mod-employee-register{
  .main-title{
    height: 25px;
    line-height: 25px;
    margin: 0;
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin-top: 25px;
  }
  .sub-title{
    height: 42px;
    line-height: 20px;
    font-size: 14px;
    color: #666;
  }
  .company-register-submit{
    margin-top: 8px;
    .register-prev-btn{
      width: 150px;
      background: #B3C0C9;
      border-color: #B3C0C9;
      color: #fff;
      &:active{
        color: #f2f2f2;
      }
    }
    .register-submit-btn{
      width: 150px;
      float: right;
    }
  }
  .login-imgcode{
    width: 215px;
    float: left;
    zoom: 1;
    margin-right: 10px;
  }
  .img-code{
    float: right;
    cursor: pointer;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
  }
  .main-tips{
    padding-top: 130px;
    text-align: center;
    .wait-img{
      width: 60px;
      height: 60px;
      display: inline-block;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('/imgs/pc_wait.png');
    }
    .wait-title{
      font-size: 20px;
      color: #333;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      margin: 10px auto 20px;
    }
  }
  .sub-tips{
    font-size: 14px;
    color: #666;
    line-height: 24px;
    text-align: center;
    margin-bottom: 54px;
  }
  .reback-button{
    width: 100%;
    background: #fff;
    &:hover{
      color: #409EFF;
      background: #fff;
    }
    &:active{
        color: #409EFF;
        background: #fff;
    }
    &:focus{
      color: #409EFF;
      background: #fff;
    }
  }
  .el-form-item.is-success .el-input__inner{
    border-color: #dcdfe6;
  }
}
</style>
