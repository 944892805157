<template>
	<div class="mod-get-password">
		<div class="headers">
			<el-button type="text" style="padding:0px 0 15px" icon="el-icon-arrow-left" @click="onReback">返回登录</el-button>
		</div>
		<el-steps :class="['get-password-steps', active === 2 ? 'is-ok' : '']" :active="active" align-center>
			<el-step title="手机号验证" />
			<el-step title="重置密码" />
		</el-steps>
		<el-form v-if="active === 1" ref="phoneForm" :model="phoneForm" :rules="phoneRules" label-width="0px">
			<el-form-item prop="account">
				<el-input
					ref="phoneFormAccount"
					v-model.trim="phoneForm.account"
					:maxlength="11"
					clearable
					placeholder="请输入注册时的手机号"
					@input="onClearAccountInput"
					@keyup.enter.native="phoneSubmit"
					/>
			</el-form-item>
			<el-form-item prop="code">
				<el-input
					ref="phoneFormCode"
					v-model.trim="phoneForm.code"
					placeholder="请输入短信验证码"
					maxlength="6"
					class="input-code"
					@input="onClearCodeInput"
					@keyup.enter.native="phoneSubmit"
					>
					<code-button slot="suffix" @change="onGetCode" />
				</el-input>
			</el-form-item>
			<el-form-item class="login-submit">
				<el-button
					type="primary"
					:loading="phoneloading"
					class="login-btn"
					@click="onSubmitPhoneForm"
					>{{ phoneloading?'提交中':'找回密码' }}</el-button>
			</el-form-item>
		</el-form>
		<el-form v-if="active === 2" ref="passwordForm" :model="passwordForm" :rules="passwordRules" label-width="0px">
			<el-form-item prop="password">
				<el-popover
					placement="bottom-start"
					popper-class="show-center"
					width="320"
					trigger="focus"
					>
					<password-strength :value="passwordLevel" />
					<el-input
						slot="reference"
						ref="passwordFormPassword"
						v-model.trim="passwordForm.password"
						:type="isPassword ? 'password' : 'text'"
						placeholder="请设置新密码"
						maxlength="16"
						@input.native="inputPasswd"
						@input="onShowPasswordLevel"
						@keyup.enter.native="passwordSubmit"
						>
						<i
							slot="suffix"
							:class="['crm-icon', isPassword ? 'icon-yanjing-bi' : 'icon-yanjing', 'login-eyes']"
							@click="isPassword=!isPassword"
							/>
					</el-input>
				</el-popover>
			</el-form-item>
			<el-form-item prop="passwordConfirm">
				<el-popover
					placement="bottom-start"
					popper-class="show-center"
					width="320"
					trigger="focus"
					>
					<password-strength :value="passwordConfirmLevel" />
					<el-input
						slot="reference"
						ref="passwordFormpasswordConfirm"
						v-model.trim="passwordForm.passwordConfirm"
						:type="isPasswordConfirm ? 'password' : 'text'"
						placeholder="请再次输入新密码"
						maxlength="16"
						@input.native="inputPasswd"
						@input="onShowPasswordConfirmLevel"
						@keyup.enter.native="passwordSubmit"
						>
						<i
							slot="suffix"
							:class="['crm-icon', isPasswordConfirm ? 'icon-yanjing-bi' : 'icon-yanjing', 'login-eyes']"
							@click="isPasswordConfirm=!isPasswordConfirm"
							/>
					</el-input>
				</el-popover>
			</el-form-item>
			<el-form-item class="login-submit">
				<el-button
					ref="submit"
					type="primary"
					:loading="loading"
					class="login-btn"
					@click="onSubmitForm"
					>{{ loading?'提交中':'保存并登录' }}</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import codeButton from './code-button.vue'
import passwordStrength from './password-strength.vue'
import cookie from '@jw/ability-business-components/tools/lib/cookie'
import Server from './api.js'
const Api = new Server()
export default {
  components: {
    codeButton,
    passwordStrength
  },
  data () {
    const self = this
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号码'))
      } else if (!/^1[0-9]{10}$/.test(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        Api.CheckPhoneValidate(value).then(({ data }) => {
          if (data.result) {
            callback()
          } else {
            callback(new Error('该手机号未注册'))
          }
        }).catch(({ response }) => {
          Server.$message(self.$message, response)
        })
      }
    }
    const checkCode = (rule, value, callback) => {
      if (self.shouldCheckTotal) {
        if (self.checkFormOfSubmit && !value) {
          callback(new Error('请输入验证码'))
        } else {
          callback(new Error('操作过于频繁，请休息5分钟再试'))
        }
      } else {
        if (!value) {
          callback(new Error('请输入验证码'))
        } else if (value.length < 6) {
          callback(new Error('请输入6位数验证码'))
        } else if (!self.phoneCodeOk) {
          callback(new Error(self.phoneCodeMsg))
        } else {
          callback()
        }
      }
    }
    return {
      active: 1,
      phoneForm: {
        account: '',
        code: ''
      },
      shouldCheckTotal: false,
      checkFormOfSubmit: false,
      phoneRules: {
        account: [{ required: true, validator: checkPhone, trigger: 'blur' }],
        code: [{ required: true, validator: checkCode, trigger: 'blur' }]
      },
      phoneloading: false,
      phoneCodeOk: true,
      phoneCodeMsg: '',
      passwordForm: {
        password: '',
        passwordConfirm: ''
      },
      passwordRules: {
        password: [{
          required: true,
          trigger: 'blur',
          validator (rule, value, callback) {
            if (!value) {
              callback(new Error('请输入6-16位数的密码'))
            } else if (value.length < 6 || value.length > 16) {
              callback(new Error('请输入6-16位数的密码'))
            } else if (self.validatorPass(value) < 2) {
              callback(new Error('密码至少6位且含字母、数字、符号中2种'))
            } else {
              callback()
            }
          }
        }],
        passwordConfirm: [{
          required: true,
          trigger: 'blur',
          validator (rule, value, callback) {
            if (!value) {
              callback(new Error('请输入6-16位数的密码'))
            } else if (value.length < 6 || value.length > 16) {
              callback(new Error('请输入6-16位数的密码'))
            } else if (self.passwordForm.password && self.passwordForm.passwordConfirm !== self.passwordForm.password) {
              callback(new Error('密码输入不一致，请重新输入'))
            } else if (self.errMsg) {
              callback(new Error(self.errMsg))
            } else if (self.validatorPass(value) < 2) {
              callback(new Error('密码至少6位且含字母、数字、符号中2种'))
            } else {
              callback()
            }
          }
        }]
      },
      errMsg: '',
      isPassword: true,
      passwordLevel: 0,
      isPasswordConfirm: true,
      passwordConfirmLevel: 0,
      loading: false
    }
  },
  mounted () {
    this.shouldCheckTotal = (Number(cookie.getCookieUtil('_findpassword')) || 0) > 5
  },
  methods: {
    onReback () {
      this.$emit('change', 'loginBox')
    },
    onClearAccountInput (value) {
      this.phoneForm.account = value
      this.$nextTick(() => {
        this.phoneForm.account = value.replace(/\D/g, '')
      })
    },
    onClearCodeInput (value) {
      this.phoneForm.code = value
      this.$nextTick(() => {
        this.phoneForm.code = value.replace(/\D/g, '')
      })
    },
    onGetCode (done) {
      this.$refs.phoneForm.validateField('account', (e) => {
        if (!e) {
          const total = Number(cookie.getCookieUtil('_findpassword')) || 0
          if (total === 5) {
            this.shouldCheckTotal = true
            cookie.setCookieUtil('_findpassword', 6, new Date(Date.now() + 5 * 60 * 1000))
            setTimeout(() => {
              this.shouldCheckTotal = false
            }, 5 * 60 * 1000)
            this.$refs.phoneForm.validateField('code', () => {})
          }
          if (total > 5) {
            this.$refs.phoneForm.validateField('code', () => {})
          }
          if (total < 5) {
            this.$refs.phoneForm.clearValidate('code')
            cookie.setCookieUtil('_findpassword', total + 1, new Date(Date.now() + 2 * 60 * 1000))
            this.$refs.phoneFormCode.focus()
            Api.postPhoneCode({
              phone: this.phoneForm.account,
              type: 2
            }).catch(({ response }) => {
              Server.$message(this.$message, response)
            })
            done && done()
          }
        }
      })
    },
    phoneSubmit (e) {
      if (this.phoneForm.account && !this.phoneForm.code) {
        this.$refs.phoneFormCode.focus()
      } else if (!this.phoneForm.account && this.phoneForm.code) {
        this.$refs.phoneFormAccount.focus()
      } else if (this.phoneForm.account && this.phoneForm.code) {
        e.target.blur()
        this.onSubmitPhoneForm()
      } else {}
    },
    onSubmitPhoneForm () {
      this.checkFormOfSubmit = true
      this.phoneCodeOk = true
      this.phoneCodeMsg = ''
      this.$refs.phoneForm.clearValidate()
      this.$refs.phoneForm.validate().then(res => {
        this.phoneloading = true
        Api.checkPhoneCode({
          phone: this.phoneForm.account,
          type: 2,
          code: this.phoneForm.code
        }).then(({ data }) => {
          if (data.result) {
            this.active = 2
          } else {
            this.phoneCodeOk = false
            this.phoneCodeMsg = '校验失败'
            this.$refs.phoneForm.validateField('code', () => {})
          }
        }).catch(({ response }) => {
          if (response && response.status === 400 && response.data && (response.data.msg === '验证码错误' || response.data.msg === '验证码已失效，请重新获取' || response.data.msg === '验证码已失效,请重新获取')) {
            this.phoneCodeOk = false
            this.phoneCodeMsg = response.data.msg
            this.$refs.phoneForm.validateField('code', () => {})
          } else {
            Server.$message(this.$message, response)
          }
        }).finally(() => {
          this.phoneloading = false
        })
      }).catch(() => {
      }).finally(() => {
        this.checkFormOfSubmit = false
      })
    },
    inputPasswd (e) {
      const reg = /[\s\u2E80-\u2EFF\u2F00-\u2FDF\u3000-\u303F\u31C0-\u31EF\u3200-\u32FF\u3300-\u33FF\u3400-\u4DBF\u4DC0-\u4DFF\u4E00-\u9FBF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF]+/g
      e.target.value = e.target.value.replace(reg, '')
    },
    validatorPass (pass) {
      if (pass) {
        if (pass.length < 6) {
          return 1
        }
        // eslint-disable-next-line no-useless-escape
        const regs = [/[0-9]/, /[A-Z]/, /[a-z]/, /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~]/]
        let total = 0
        for (let i = 0; i < 4; i++) {
          if (regs[i].test(pass)) {
            total = total + 1
          }
        }
        if (total === 1) {
          return 1
        }
        if (total === 2) {
          return 2
        }
        if (total > 2) {
          return 3
        }
      } else {
        return 0
      }
    },
    onShowPasswordLevel (val) {
      this.$nextTick(() => {
        this.passwordLevel = this.validatorPass(val)
      })
    },
    onShowPasswordConfirmLevel (val) {
      this.$nextTick(() => {
        this.passwordConfirmLevel = this.validatorPass(val)
      })
    },
    passwordSubmit (e) {
      if (this.passwordForm.password && !this.passwordForm.passwordConfirm) {
        this.$refs.passwordFormpasswordConfirm.focus()
      } else if (!this.passwordForm.password && this.passwordForm.passwordConfirm) {
        this.$refs.passwordFormpassword.focus()
      } else if (this.passwordForm.password && this.passwordForm.passwordConfirm) {
        e.target.blur()
        this.onSubmitForm()
      } else {}
    },
    login () {
      window.location.reload()
    },
    onSubmitForm () {
      this.errMsg = ''
      this.$refs.passwordForm.clearValidate()
      this.$refs.passwordForm.validate().then(res => {
        this.loading = true
        Api.findPassword({
          phone: this.phoneForm.account,
          code: this.phoneForm.code,
          userPasswd: this.passwordForm.password,
          userPasswdConfirm: this.passwordForm.passwordConfirm
        }).then(res => {
          this.$message({
            message: '密码重置成功',
            type: 'success'
          })
          setTimeout(() => { this.login() }, 500)
        }).catch(({ response }) => {
          if (response && response.status === 400 && response.data && (response.data.msg === '验证码已失效，请重新获取' || response.data.msg === '验证码已失效,请重新获取')) {
            this.errMsg = response.data.msg
            this.$refs.passwordForm.validateField('passwordConfirm', () => {})
          } else {
            Server.$message(this.$message, response)
          }
        }).finally(() => {
          this.loading = false
        })
      })
    }
  }
}
</script>

<style lang="less">
.el-popover.show-center{
  text-align: center;
}
.mod-get-password{
  height: 100%;
  .headers{
    width: 100%;
    border-bottom: 1px solid #cecece;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .get-password-steps .el-step.is-horizontal{
    margin-bottom: 10px;
    .el-step__head{
      .el-step__line{
        height: 1px;
        background-color: #cecece;
        top: 15px;
      }
      .el-step__icon{
        width: 30px;
        height: 30px;
        border: 1px solid #cecece;
        .el-step__icon-inner{
          font-weight: normal;
          color: #999;
        }
      }
      &.is-finish{
        .el-step__icon{
          border-color: #0099ff;
          background-color: #0099ff;
          .el-step__icon-inner{
            color: #fff;
          }
        }
      }
    }
    .el-step__main .el-step__title{
      font-weight: normal;
      font-size: 12px;
      color: #666;
      &.is-finish{
        color: #0099ff;
      }
    }
  }
  .get-password-steps.is-ok .el-step.is-horizontal:first-child{
    .el-step__head.is-finish .el-step__icon{
      border-color: #0099ff;
      background-color: #fff;
      .el-step__icon-inner{
        color: #0099ff;
      }
    }
  }
  .login-btn {
    width: 100%;
  }
  .input-code .el-input__suffix{
    right: 15px;
  }
  .login-eyes {
    font-size: 18px;
    cursor: pointer;
  }
  .el-form-item.is-success .el-input__inner{
    border-color: #dcdfe6;
  }
}
</style>
