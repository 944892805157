<template>
	<div class="mod-company-register">
		<template v-if="isRegister">
			<h3 class="main-title">填写企业注册信息</h3>
			<p class="sub-title"><span>已有账号？</span><el-button style="padding-top:6px;" type="text" @click="onSelectLogin">直接登录</el-button></p>
			<el-form ref="form" :model="form" :rules="rules" label-width="0px">
				<el-form-item prop="companyName">
					<el-input
						v-model.trim="form.companyName"
						maxlength="50"
						clearable
						placeholder="请输入企业名称"
						/>
				</el-form-item>
				<el-form-item prop="name">
					<el-input
						v-model.trim="form.name"
						maxlength="10"
						clearable
						placeholder="请输入姓名"
						/>
				</el-form-item>
				<el-form-item prop="mobile">
					<el-input
						v-model.trim="form.mobile"
						:maxlength="11"
						clearable
						placeholder="请输入手机号"
						@input="onClearAccountInput"
						/>
				</el-form-item>
				<el-form-item prop="code">
					<el-input
						ref="phoneFormCode"
						v-model.trim="form.code"
						placeholder="请输入短信验证码"
						maxlength="6"
						class="input-code"
						@input="onClearCodeInput"
						>
						<code-button slot="suffix" @change="onGetCode" />
					</el-input>
				</el-form-item>
				<el-form-item prop="product">
					<el-select v-model="form.product" style="width:100%;" placeholder="请选择开通产品">
						<el-option
							v-for="item in products"
							:key="item.configId"
							:label="item.toolShowName"
							:value="item.configId"
							/>
					</el-select>
				</el-form-item>
				<el-form-item class="company-register-submit">
					<el-button
						type="primary"
						class="register-prev-btn"
						@click="onPrev"
						>上一步</el-button>
					<el-button
						type="primary"
						:loading="loading"
						class="register-submit-btn"
						@click="onSubmit"
						>{{ loading?'提交中':'提交' }}</el-button>
				</el-form-item>
			</el-form>
		</template>
		<template v-if="!isRegister">
			<div class="prew-area">
				<div class="show-area">
					<div class="show-content">
						<h3 class="title-text">{{ prew.successTip }}</h3>
						<h5 v-if="prew.operateTip" class="sub-title-text">{{ prew.operateTip }}</h5>
						<img v-if="prew.operateImgUrl" class="qrcode-img" :src="prew.operateImgUrl">
						<p v-if="prew.operateContent" class="tips-text">{{ prew.operateContent }}</p>
					</div>
				</div>
				<div class="button-area">
					<el-button type="primary" plain @click="onOpenPage">{{ prew.buttonName }}</el-button>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import codeButton from './code-button.vue'
import cookie from '@jw/ability-business-components/tools/lib/cookie'
import Server from './api.js'
const Api = new Server()
export default {
  components: {
    codeButton
  },
  data () {
    const self = this
    const checkCompanyName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入公司名称'))
      } else if (self.companyNameErrorText) {
        callback(new Error(self.companyNameErrorText))
      } else {
        callback()
      }
    }
    const checkName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入姓名'))
      } else {
        callback()
      }
    }
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号码'))
      } if (self.mobileErrorText) {
        callback(new Error(self.mobileErrorText))
      } else if (!/^1[0-9]{10}$/.test(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    }
    const checkPhoneCode = (rule, value, callback) => {
      if (self.shouldCheckCodeTotal) {
        callback(new Error('操作过于频繁，请休息5分钟再试'))
      } else {
        if (!value) {
          callback(new Error('请输入验证码'))
        } else if (value.length < 6) {
          callback(new Error('请输入6位数验证码'))
        } else if (self.codeErrorText) {
          callback(new Error(self.codeErrorText))
        } else {
          callback()
        }
      }
    }
    const checkProduct = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入开通产品'))
      } else if (self.productErrorText) {
        callback(new Error(self.productErrorText))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      isRegister: true,
      form: {
        companyName: '',
        name: '',
        mobile: '',
        code: '',
        product: ''
      },
      prew: {
        successTip: '',
        operateTip: '',
        operateImgUrl: '',
        operateContent: '',
        buttonName: '',
        buttonLinkUrl: ''
      },
      companyNameErrorText: '',
      mobileErrorText: '',
      codeErrorText: '',
      productErrorText: '',
      shouldCheckCodeTotal: false,
      rules: {
        companyName: [{ validator: checkCompanyName, trigger: 'blur' }],
        name: [{ validator: checkName, trigger: 'blur' }],
        mobile: [{ validator: checkPhone, trigger: 'blur' }],
        code: [{ validator: checkPhoneCode, trigger: 'blur' }],
        product: [{ validator: checkProduct, trigger: 'change' }]
      },
      products: []
    }
  },
  mounted () {
    this.shouldCheckTotal = (Number(cookie.getCookieUtil('_register_company')) || 0) > 5
    Api.getTools().then(({ data }) => {
      this.products = data
    }).catch(res => {
      Server.$message(self.$message, res && res.response)
    })
  },
  methods: {
    onSelectLogin () {
      this.$emit('change', 'loginBox')
    },
    onClearAccountInput (value) {
      this.form.mobile = value
      this.$nextTick(() => {
        this.form.mobile = value.replace(/\D/g, '')
      })
    },
    onClearCodeInput (value) {
      this.codeErrorText = null
      this.form.code = value
      this.$nextTick(() => {
        this.form.code = value.replace(/\D/g, '')
      })
    },
    onGetCode (done) {
      this.$refs.form.validateField('mobile', (e) => {
        if (!e) {
          const total = Number(cookie.getCookieUtil('_register_company')) || 0
          if (total === 5) {
            this.shouldCheckCodeTotal = true
            cookie.setCookieUtil('_register_company', 6, new Date(Date.now() + 5 * 60 * 1000))
            setTimeout(() => {
              this.shouldCheckCodeTotal = false
            }, 5 * 60 * 1000)
            this.$refs.form.validateField('code', () => {})
          }
          if (total > 5) {
            this.$refs.form.validateField('code', () => {})
          }
          if (total < 5) {
            this.$refs.form.clearValidate('code')
            cookie.setCookieUtil('_register_company', total + 1, new Date(Date.now() + 2 * 60 * 1000))
            Api.postPhoneCode({
              phone: this.form.mobile,
              type: 6,
              isCheckPhone: 2
            }).catch(({ response }) => {
              Server.$message(this.$message, response)
            })
            this.$refs.phoneFormCode.focus()
            done && done()
          }
        }
      })
    },
    onPrev () {
      this.$emit('change', 'selectRegister')
    },
    onOpenPage () {
      if (this.prew.buttonLinkUrl) {
        const links = document.createElement('a')
        links.style.display = 'none'
        links.setAttribute('href', this.prew.buttonLinkUrl);
        (document.body[0] || document.body).appendChild(links)
        setTimeout(() => {
          links.click();
          (document.body[0] || document.body).removeChild(links)
        }, 10)
      }
    },
    onSubmit () {
      this.$refs.form.validate().then(res => {
        this.loading = true
        Api.postCompany({
          pName: this.form.companyName,
          phone: this.form.mobile,
          name: this.form.name,
          code: this.form.code,
          configId: this.form.product
        }).then(({ data }) => {
          this.prew = {
            successTip: data.successTip,
            operateTip: data.operateTip,
            operateImgUrl: data.operateImgUrl,
            operateContent: data.operateContent,
            buttonName: data.buttonName,
            buttonLinkUrl: data.buttonLinkUrl
          }
          this.isRegister = false
        }).catch(({ response }) => {
          if (response && response.status === 406) {
            const data = response.data
            if (data.code === 406001) {
              this.codeErrorText = data.msg
              this.$refs.form.validateField('code', () => {})
            }
            if (data.code === 406002) {
              this.$confirm('注册失败，您填写的企业已拥有企业主账号，您可以注册申请成为该企业中的一员。', '提示', {
                confirmButtonText: '注册成为一员',
                cancelButtonText: '取消',
                customClass: 'dialog-mod-register-company'
              }).then(res => {
                this.$emit('change', 'employeeRegister', this.form.companyName, this.form.mobile)
              }).catch(() => {})
            }
            if (data.code === 406004) {
              this.$confirm('注册失败，当前手机号已被其他账号使用，请直接登录已有账号。', '提示', {
                confirmButtonText: '立即登录',
                showCancelButton: false,
                customClass: 'dialog-mod-register-company'
              }).then(res => {
                this.$emit('change', 'loginBox')
              }).catch(() => {})
            }
          } else {
            Server.$message(this.$message, response)
          }
        }).finally(() => {
          this.loading = false
        })
      }).catch(() => {})
    }
  }
}
</script>

<style lang="less">
.dialog-mod-register-company{
  width: 420px;
  height: 200px;
  .el-message-box__header{
    border-bottom: 1px solid #cbcdd4;
  }
  .el-message-box__content{
    padding: 30px 15px;
  }
}
.mod-company-register{
  .main-title{
    height: 25px;
    line-height: 25px;
    margin: 0;
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin-top: 25px;
  }
  .sub-title{
    height: 42px;
    line-height: 20px;
    font-size: 14px;
    color: #666;
  }
  .company-register-submit{
    margin-top: 8px;
    .register-prev-btn{
      width: 150px;
      background: #B3C0C9;
      border-color: #B3C0C9;
      color: #fff;
      &:active{
        color: #f2f2f2;
      }
    }
    .register-submit-btn{
      width: 150px;
      float: right;
    }
  }
  .prew-area{
    width: 100%;
    height: 490px;
    .show-area{
      width: 100%;
      height: 370px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      .show-content{
        display: inline-block;
        box-sizing: border-box;
        padding: 0 10px;
        .title-text{
          color: #409EFF;
          margin: 0;
          font-size: 20px;
          text-align: center;
          word-break: break-all;
        }
        .sub-title-text{
          color:#333;
          font-size: 16px;
          margin: 0;
          text-align: center;
          padding-top: 20px;
          word-break: break-all;
        }
        .qrcode-img{
          border: none;
          width: 156px;
          height: 156px;
          display: inline-block;
          padding-top: 12px;
        }
        .tips-text{
          padding-top: 12px;
          font-size: 14px;
          color: #666;
          text-align: center;
          word-break: break-all;
        }
      }
    }
    .button-area{
      box-sizing: border-box;
      padding: 0 20px;
      .el-button--primary.is-plain{
        width: 100%;
        background: #fff;
        height:40px;
        &:hover{
          color: #409EFF;
        }
        &:active{
            color: #409EFF;
        }
        &:focus{
          color: #409EFF;
        }
      }
    }
  }
  .el-form-item.is-success .el-input__inner{
    border-color: #dcdfe6;
  }
}
</style>
